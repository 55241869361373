@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100..900&display=swap');

:root {
  --color-white: #FFFFFF;
  --color-black: #212121;
  --color-grey: #6c6c6c;
  --color-red: #dd4b39;
  --color-grey-secondary: #ddd;
  --color-primary: #EECD78;
  --color-green: #00cf6f;
  --color-light: #eee;
  --color-bg: #f8f8f8;
  --color-purple: #7880ee;
  --color-white-60: rgb(255 255 255 / 60%);
}

* {
  font-family: "Noto Sans Georgian", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
body, section {
  background-color: var(--color-bg);
}
.container {
  max-width: 1400px;
}
a {
  text-decoration: none;
}

.form-control {
  outline: none !important;
  box-shadow: none !important;
}

.h52 {
  height: 52px;
}

/* header */
.headerTop {
  padding: 10px 0px;
  background-color: var(--color-white);
}

.header {
  background-color: #1d293e;
}

.logo {
  padding: 10px 0px;
}

.logo img {
  height: 60px;
  width: auto;
}

.shortNav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.shortNav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.shortNav ul li {
  display: inline-block;
  position: relative;
}

.shortNav ul li a {
  color: var(--color-white);
  margin-right: 20px;
  font-size: 1rem;
  line-height: 1.8rem;
  transition: color 0.3s ease;
}

.shortNav ul li a span {
  margin-left: 3px;
}

.shortNav ul li a span svg {
  font-size: 0.6rem;
}

.shortNav ul li:hover a {
  color: var(--color-primary);
  transition: color 0.3s ease;
}

.shortNav ul li ul.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  padding-top: 18px;
  z-index: 11;
}

.shortNav ul li:hover ul.dropdown {
  display: block;
}

.shortNav ul li ul.dropdown li {
  display: block;
  margin: 0;
  padding: 0;
}

.shortNav ul li ul.dropdown li a {
  color: var(--color-black);
  text-decoration: none;
  margin: 0;
  padding: 8px 20px;
  display: block;
  transition: 0.3s ease;
}

.shortNav ul li ul.dropdown li a:hover {
  color: var(--color-primary);
  padding-left: 30px;
  transition: 0.3s ease;
}

.dropdownBox {
  min-width: 180px;
  padding: 4px 0px;
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  box-shadow: 0px 2px 4px 0px var(--color-light);
}

.rightNav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.rightNav a {
  color: var(--color-white);
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: block;
  transition: 0.3s ease;
  margin-left: 20px;
}

.rightNav a:first-child {
  margin-left: 0px;
}

.rightNav a:hover {
  color: var(--color-primary);
  transition: 0.3s ease;
}

.loginBtn {
  padding: 10px !important;
  font-size: 1rem;
  box-shadow: 0px 1px 4px 0px rgb(48 169 83 / 35%);
  border: 2px solid var(--color-white-60);
  font-weight: bold;
  border-radius: 10px;
}

.sideMenu span svg {
  font-size: 1.5rem;
  margin-top: 2px;
}

.HeaderTopLeft a {
  color: var(--color-black);
  transition: 0.3s ease;
  margin-right: 24px;
}

.HeaderTopLeft a:hover {
  color: var(--color-primary);
  transition: 0.3s ease;
}

.HeaderTopLeft a span {
  margin-right: 6px;
}

.HeaderTopLeft a span svg {
  font-size: 0.8rem;
}

.HeaderTopRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.HeaderTopRight a {
  color: var(--color-black);
  transition: 0.3s ease;
  margin-right: 24px;
}

.HeaderTopRight a:hover {
  color: var(--color-primary);
  transition: 0.3s ease;
}

.HeaderTopRight a span {
  margin-right: 6px;
}

.HeaderTopRight a span svg {
  font-size: 0.8rem;
}

.socialTop {
  font-size: 0.8rem;
}
.socialTop svg {
  font-size: 15px;
  color: var(--color-black);
}
/* Footer */
.footer {
  position: relative;
  width: 100%;
  padding: 100px 0 0;
  overflow: hidden;
  background-color: #24324a;
}

.footer_menu h4 {
  color: var(--color-primary);
  margin-bottom: 32px;
}

.footer_menu ul {
  margin: 0;
  padding: 0;
}

.footer_menu ul li {
  position: relative;
  list-style: none;
  margin-bottom: 10px;
}

.footer_menu ul li a svg {
  color: var(--color-white);
  margin-right: 6px;
  transition: 0.3s ease;
}

.footer_menu ul li a {
  color: var(--color-white);
  transition: 0.3s ease;
}

.footer_menu ul li:before {
  content: '';
  position: absolute;
}

.footer_menu ul li:hover a {
  color: var(--color-primary);
  transition: 0.3s ease;
}
.footer_menu ul li:hover a

.socialFtrBox:hover a svg {
  color: var(--color-white);
  transition: 0.3s ease;
}

/* about */
.aboutSection {
  padding: 80px 0px;
}

.aboutBox h1 {
  font-size: 2.4rem;
  margin-bottom: 24px;
  color: var(--color-black);
}

.aboutBox p {
  font-size: 1rem;
  line-height: 1.8rem;
  color: var(--color-black);
}

.aboutImage {
  height: 600px;
  width: 100%;
  overflow: hidden;
  border: 10px solid var(--color-primary);
  border-radius: 30px;
}

.aboutImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.aboutImage .react-loading-skeleton {
  line-height: var(--bs-body-line-height);
}

/* Slider */
.sliderSection {
  position: relative;
  background-image: url(../image/slider.jpg);
  background-position: center;
  background-repeat: no-repeat;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderSection:before {
  background-color: rgba(29, 41, 62, .702);
  bottom: 0;
  content: "";
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.SliderContent {
  z-index: 1;
}

.SliderText h1 {
  color: var(--color-white);
  font-size: 3rem;
  margin-bottom: 1rem;
}

.SliderText pre {
  color: var(--color-white);
  font-size: 1.4rem;
  margin-bottom: 24px;
}

.sliderSearchInput {
  position: relative;
}

.sliderSearchInput input {
  width: 100%;
  height: 70px;
  border-radius: 30px;
  border: 0;
  outline: 0;
  padding: 10px 20px;
  padding-right: 70px;
  font-weight: bold;
  font-size: 1.1rem;
  color: var(--color-black);
}

.sliderSearchInput .searchButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 70px;
  width: 70px;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  border: 3px solid var(--color-white);
  background: var(--color-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
}

.sliderSearchInput .searchButton:hover {
  border: 3px solid var(--color-primary);
  transition: 0.5s ease;
}

.sliderSearchInput .searchButton svg {
  color: var(--color-white);
}

/* Cities */
.citySection {
  padding: 80px 0px;
}

.citySlider .singleCityBox {
  min-height: 340px;
  cursor: pointer;
}

.singleCityBoxImage {
  position: relative;
  height: 340px;
  width: 100%;
  border: 0;
  border-radius: 16px;
  overflow: hidden;
}

.singleCityBox:hover .singleCityBoxImage img {
  transform: scale(1.1);
  transition: 1s ease;
}

.cityOverlay {
  height: 100%;
  width: 100%;
}

.cityOverlay::before {
  background-color: rgba(29, 41, 62, .3);
  bottom: 0;
  content: "";
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 16px;
}

.singleCityBoxImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 1s ease;
}

.overCity {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
  padding-bottom: 30px;
}

.overCity h3 {
  font-size: 1.4rem;
  color: var(--color-white);
  font-weight: bold;
}

.overCity p {
  color: var(--color-white);
  margin-bottom: 0px;
  font-size: 1.2rem;
}

.citySlider .swiper-button-next {
  background: var(--color-white);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: -50px;
  transition: 0.3s ease;
}
.citySlider:hover .swiper-button-next {
  margin-right: 0px;
  transition: 0.3s ease;
}


.citySlider .swiper-button-next::after {
  font-size: 18px;
  color: var(--color-primary);
  font-weight: bold;
}

.citySlider .swiper-button-prev {
  background: var(--color-white);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -50px;
  transition: 0.3s ease;
}
.citySlider:hover .swiper-button-prev {
  margin-left: 0px;
  transition: 0.3s ease;
}

.citySlider .swiper-button-prev::after {
  font-size: 18px;
  color: var(--color-primary);
  font-weight: bold;
}

/* auth modal */
.authModal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #031A267A;
  backdrop-filter: blur(5px);
  visibility: hidden;
  height: 100%;
  width: 100%;
  z-index: 11;
  transition-duration: 0.3s;
}

.authModal.active {
  visibility: visible;
  transition-duration: 0.3s;
}

.authModalOuter {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 800px;
  height: fit-content;
  border-radius: 8px;
  padding: 24px;
  background-color: var(--color-white);
  opacity: 0;
  top: -100px;
  transition-duration: 0.3s;
}

.authModal.active .authModalOuter {
  opacity: 1;
  top: 0;
  transition-duration: 0.3s;
}

.closeAuthModal {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-primary);
  cursor: pointer;
  border-radius: 50%;
  margin-top: -12px;
  margin-right: -12px;
}

.closeAuthModal svg {
  color: var(--color-white);
  font-size: 1.5rem;
}

/* main css */
.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionHeaderLeft {
  margin-bottom: 24px;
}

.sectionHeaderLeft h2 {
  color: var(--color-black);
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 8px;
  font-weight: bold;
}

.sectionHeaderLeft p {
  color: var(--color-black);
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin-bottom: 0px;
}

.sectionHeaderRight a {
  color: var(--color-black);
  font-size: 1.1rem;
  transition: 0.3s ease;
  font-weight: bold;
}

.sectionHeaderRight a:hover svg {
  margin-left: 4px;
  transition: 0.3s ease;
}

.sectionHeaderRight svg {
  font-size: 1.1rem;
  color: var(--color-black);
  margin-left: 10px;
  transition: 0.3s ease;
}

.serviceSection {
  padding: 40px 0px;
}

.videoSection {
  padding: 40px 0px;
}

.videoSlider .singleVideoBox {
  min-height: 220px;
  cursor: pointer;
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 16px;
  overflow: hidden;
  line-height: 0;
}

.videoSlider .swiper-button-next {
  background: var(--color-white);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: -50px;
  transition: 0.3s ease;
}
.videoSection:hover .swiper-button-next {
  margin-right: 0px;
  transition: 0.3s ease;
}
.videoSlider .swiper-button-next::after {
  font-size: 18px;
  color: var(--color-primary);
  font-weight: bold;
}

.videoSlider .swiper-button-prev {
  background: var(--color-white);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -50px;
  transition: 0.3s ease;
}
.videoSection:hover .swiper-button-prev {
  margin-left: 0px;
  transition: 0.3s ease;
}

.videoSlider .swiper-button-prev::after {
  font-size: 18px;
  color: var(--color-primary);
  font-weight: bold;
}

.developerSection {
  padding: 80px 0px;
}

.developerSlider .singleDeveloperBox {
  min-height: 210px;
  cursor: pointer;
  position: relative;
  width: 100%;
  border: 1px solid #eee;
  background-color: var(--color-white);
  border-radius: 16px;
  overflow: hidden;
  line-height: 0;
  padding: 10px;
}

.developerSlider .swiper-button-next {
  background: var(--color-primary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: -50px;
  transition: 0.3s ease;
}
.developerSection:hover .swiper-button-next {
  margin-right: 0px;
  transition: 0.3s ease;
}
.developerSlider .swiper-button-next::after {
  font-size: 18px;
  color: var(--color-white);
  font-weight: bold;
}

.developerSlider .swiper-button-prev {
  background: var(--color-primary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -50px;
  transition: 0.3s ease;
}
.developerSection:hover .swiper-button-next {
  margin-left: 0px;
  transition: 0.3s ease;
}

.developerSlider .swiper-button-prev::after {
  font-size: 18px;
  color: var(--color-white);
  font-weight: bold;
}

.developerDroperImage {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.developerDroperImage img {
  height: 110px;
  width: 100%;
  object-fit: contain;
}

.developerDroperTitle {
  border-top: 1px solid var(--color-light);
}

.developerDroperTitle h4 {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0px;
  font-weight: bold;
  line-height: 1.8rem;
  padding: 8px;
  color: var(--color-black);
}

.mapSection {
  padding: 40px 0px;
}

.mapbox {
  margin: 0 auto;
  text-align: center;
}

#GEO3015,
#GEO3027,
#GEO3028,
#GEO3029,
#GEO3028,
#GEO3029,
#GEO3030,
#GEO3033,
#GEO3034,
#GEO3035,
#GEO3037,
#GEO3036,
#GEO3038,
#GEO3039 {
  cursor: pointer;
}

#GEO3027:hover,
#GEO3028:hover,
#GEO3029:hover,
#GEO3015:hover,
#GEO3030:hover,
#GEO3035:hover,
#GEO3038:hover,
#GEO3039:hover,
#GEO3037:hover,
#GEO3034:hover,
#GEO3033:hover,
#GEO3036:hover {
  fill: var(--color-purple);
  transition: 0.3s ease;
}

.rangText {
  pointer-events: none;
}

.singleCity {
  border-radius: 12px;
  overflow: hidden;
  height: 320px;
  width: 100%;
  position: relative;
  margin-bottom: 24px;
}

.singleCityImage {
  border-radius: 12px;
  height: 320px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: 1s ease;
}

.singleCityImage:hover {
  transform: scale(1.1);
  transition: 1s ease;
}

.singleCityImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.singleCityTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 10px 16px;
  backdrop-filter: blur(1px);
  pointer-events: none;
}

.singleCityTitle h3 {
  font-size: 1.4rem;
  color: var(--color-white);
  font-weight: bold;
}

.singleCityTitle p {
  color: var(--color-white);
  margin-bottom: 0px;
  font-size: 1.2rem;
}

.singleVideosBox {
  min-height: 220px;
  cursor: pointer;
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 16px;
  overflow: hidden;
  line-height: 0;
  margin-bottom: 24px;
}

.singleCompanyBox {
  min-height: 210px;
  cursor: pointer;
  position: relative;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 16px;
  overflow: hidden;
  line-height: 0;
  padding: 10px;
  margin-bottom: 24px;
}

.sideMenuModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #031A267A;
  z-index: 11;
  visibility: hidden;
  transition-duration: 0.3s;
}

.sideMenuModal.active {
  transition-duration: 0.3s;
  visibility: visible;
}

.sideMenuModalOuter {
  position: absolute;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  border-radius: 0px;
  padding: 24px;
  margin-right: -300px;
  background-color: var(--color-white);
  transition-duration: 0.3s;
}

.sideMenuModal.active .sideMenuModalOuter {
  margin-right: 0px;
  transition-duration: 0.3s;
}

.closeSidebarModal {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 24px;
  margin-right: 24px;
}

.closeSidebarModal svg {
  color: var(--color-black);
  font-size: 1.5rem;
}

.sideMenuHeader {
  border-bottom: 1px solid var(--color-light);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.sideMenuHeader h4 {
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.mainSideMenuBox .accordion-item {
  border: 0;
}

.mainSideMenuBox .accordion-button {
  padding: 1rem 0;
}

.mainSideMenuBox .accordion-button:not(.collapsed) {
  box-shadow: none !important;
  background: transparent !important;
  color: var(--color-black);
}

.mainSideMenuBox .accordion-button:focus {
  box-shadow: none !important;
}

.mainSideMenuBox .accordion-body {
  padding: 0;
}

.mainSideMenuBox .accordion-body ul {
  padding: 0;
  margin: 0;
  padding-left: 16px;
}

.mainSideMenuBox .accordion-body ul li {
  list-style: none;
  margin-bottom: 10px;
  transition: 0.3s ease;
}

.mainSideMenuBox .accordion-body ul li a {
  color: var(--color-black);
  font-size: 0.9rem;
}

.mainSideMenuBox .accordion-body ul li:hover {
  padding-left: 10px;
  transition: 0.3s ease;
}

/* BreadCumb */
#sectionBreadCumb {
  background-image: url(../image/bg.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

.breadcumbSection {
  position: relative;
}

.breadcumbSection::before {
  background-color: rgb(29, 41, 62);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 0;
}

.breadcumb {
  position: relative;
  padding: 90px 0 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breadcumbLeft h1 {
  font-size: 2.4rem;
  color: var(--color-white);
  font-weight: bold;
  margin-bottom: 0px;
}

.breadcumbRight ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.breadcumbRight ul li {
  list-style: none;
  color: var(--color-white);
  margin-left: 12px;

}

.breadcumbRight ul li a {
  color: var(--color-white);
  font-weight: bold;
  transition: 0.3s ease;
}

.breadcumbRight ul li a:hover {
  color: var(--color-primary);
  transition: 0.3s ease;
}

.breadcumbRight ul li svg {
  color: var(--color-white);
  font-size: 0.8rem;
}

/* contact */
.contactSection {
  padding: 80px 0px;
}

.contactLeft {
  padding-right: 16px;
}

.contactLeft h2 {
  font-size: 1.7rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.contactLeft p {
  font-size: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.contactFormGroup {
  margin-bottom: 30px;
}

.contactFormGroup label {
  width: 100%;
  color: var(--color-black);
  margin-bottom: 8px;
  font-size: 1rem;
}

.contactFormGroup input {
  width: 100%;
  height: 56px;
  border-radius: 20px;
  border: 1px solid var(--color-light);
  padding: 4px 16px;
  font-size: 1rem;
}

.contactFormGroup textarea {
  width: 100%;
  min-height: 180px;
  border-radius: 20px;
  border: 1px solid var(--color-light);
  padding: 16px;
  font-size: 1rem;
}

.sendRequest {
  border: 4px solid var(--color-primary);
  padding: 14px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 10px;
  background: var(--color-primary);
  color: var(--color-black);
  transition: 0.3s ease;
}

.sendRequest:hover {
  border: 4px solid var(--color-primary);
  background: var(--color-white);
  color: var(--color-black);
  transition: 0.3s ease;
}

.contactRight {
  padding-left: 16px;
  position: relative;
  height: 100%;
}

.contactRight h2 {
  font-size: 1.7rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.contactRight p {
  font-size: 1rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.contactSingleBox {
  margin-bottom: 24px;
}

.contactSingleBox h3 {
  font-size: 1.1rem;
  margin-bottom: 8;
  font-weight: bold;
  color: var(--color-black);
}

.contactSingleBox a {
  color: var(--color-grey);
  font-size: 1rem;
  margin-bottom: 0px;
}

/* auth */
.authContainer .nav-item {
  width: 50%;
}

.authContainer .nav-tabs {
  border: 0 !important;
}

.authContainer .nav-item button {
  width: 100%;
  height: 70px;
  padding: 0;
  line-height: 65px;
}

.authContainer .nav-item.active {
  background-color: #031A26;
}

.authContainer .nav-tabs .nav-link {
  border: 0;
  color: #484848;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0;
}

.authContainer .nav-tabs .nav-item.show .nav-link,
.authContainer .nav-tabs .nav-link.active {
  border: 0 !important;
  background-color: #f5f5f5;
}

.loginTitle {
  color: #006c70;
  font-size: 1rem;
  font-weight: bold;
}

.googleAuth {
  background-color: #ffffff;
  border: 1px solid #dd4b39;
  border-radius: 8px;
  color: #dd4b39;
  font-size: 14px;
  height: 52px;
  margin-bottom: 10px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.googleAuth span {
  display: block;
  width: 100%;
  text-align: center;
}

.googleAuth:hover {
  background-color: #dd4b39;
  border: 1px solid #dd4b39;
  color: #ffffff;
}

.orAuth {
  opacity: 1;
  border-color: #ddd;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.orAuth::before {
  background-color: rgb(255, 255, 255);
  bottom: 0px;
  color: rgb(0, 108, 112);
  content: "ან";
  font-size: 14px;
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: center;
  top: -12px;
  width: 10%;
  margin: 0px auto;
}

.authAdditionalOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.authAdditionalOptions .custom-control-label {
  opacity: 0.6;
  font-size: 0.9rem;
  padding-left: 30px;
}

.forgot {
  color: #8b91dd;
  font-size: 14px;

  line-height: 2.214;
}

.authAdditionalOptions .custom-control-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}


.authAdditionalOptions .custom-control-label::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 4px;
}

.authAdditionalOptions .custom-control-label::after {
  content: '✓';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
  display: none;
}

.authAdditionalOptions .custom-control-input:checked+.custom-control-label::after {
  color: #fff;
  display: flex;
}

.authAdditionalOptions .custom-control-input:checked+.custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.suggestRegister {
  font-size: 0.9rem;
  margin-top: 1.2rem;
  text-align: center;
}

.suggestRegister strong {
  color: #30a953;
  cursor: pointer;
}

.footerBottom {
  background-color: #1d293e;
  padding: 24px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerBottomLeft p {
  color: var(--color-light);
  margin-bottom: 0px;
  font-size: 0.9rem;
}

.footerBottomRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footerBottomRight a {
  color: var(--color-light);
  font-size: 0.9rem;
  margin-left: 24px;
}

/* news */
.mainNews {
  padding: 80px 0;
  background-color: #f7f7f7;
}


.newsCard {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 12px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 15px;
}

.newsImage {
  background-color: #1d293e;
  border-radius: 8px;
  overflow: hidden;
}

.newsImage img {
  width: 100%;
}

.newsCard h4 {
  font-size: 0.9rem;
  color: #484848;
  font-weight: bold;
  line-height: 1.4;
  margin-top: 8px;
  min-height: 70px;
  padding-top: 20px;
}

.newsDescription {
  font-size: 0.85rem;
  color: #484848;
  line-height: 1.714;
  margin-bottom: 0;
  margin-top: 20px;
}

.newsDate {
  font-size: 0.9rem;

  color: rgb(119, 119, 119);
  line-height: 1.2;
}

.mainNewsDetails {
  padding: 80px 0;
}

.postTitle {
  font-size: 2rem;
}

.postDate {
  font-size: 0.95rem;

  color: rgb(119, 119, 119);
  line-height: 1.2;
}

.postImage {
  border-radius: 8px;
  overflow: hidden;
}

.mainDeveloperDetails {
  padding: 80px 0;
}

.developerAdsAmount {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 20px;
  margin-top: 20px;
  background: var(--color-primary);
  padding: 8px 12px;
  height: 30px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 8px;
}

.developerMainImage {
  height: 200px;
  width: 100%;
  overflow: hidden;
}

.developerMainImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.developerMainTitle {
  border-top: 1px solid var(--color-light);
  border-bottom: 1px solid var(--color-light);
  padding: 8px;
  padding-top: 16px;
}

.developerMainTitle h5 {
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.8rem;
  color: var(--color-black);
}

.developerMainTitle p {
  font-size: 0.9rem;
  color: var(--color-grey);
  line-height: 1;
  margin-bottom: 12px;
  transition: 0.3s ease;
}

.developerMainTitle p:hover {
  color: var(--color-black);
  transition: 0.3s ease;
}

.developerMainFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 8px;
}

.developerSocial {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.developerSocial a {
  color: var(--color-grey);
  margin-right: 12px;
  transition: 0.3s ease;
}

.developerSocial a:hover {
  color: var(--color-primary);
  transition: 0.3s ease;
}

.developerSocial a:last-child {
  margin-right: 0px;
}

.developerLink a {
  color: var(--color-black);
  font-weight: bold;
  transition: 0.3s ease;
}

.developerLink a:hover {
  color: var(--color-primary);
  transition: 0.3s ease;
}

.singleDeveloperBox {
  min-height: 210px;
  cursor: pointer;
  position: relative;
  width: 100%;
  border: 1px solid var(--color-light);
  border-radius: 16px;
  overflow: hidden;
  line-height: 0;
  padding: 10px;
  margin-bottom: 24px;
  box-shadow: 3px 3px 10px var(--color-light);
}

.infoPageLeft {
  padding-right: 16px;
  position: relative;
}

.infoPageTitle {
  margin-bottom: 24px;
  padding-top: 30px;
  border-bottom: 1px dashed var(--color-light);
  padding-bottom: 24px;
}

.infoPageTitle h2 {
  font-size: 1.7rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.infoPageTitle span {
  font-size: 0.9rem;
}

.infoPageRight {
  padding-left: 16px;
}

.infopageButtonBox {
  margin-top: 50px;
}

.infopageButton {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: 12px 24px;
  font-size: 1rem;
  color: var(--color-grey);
  font-weight: bold;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
}

.infopageButton:hover {
  background: var(--color-grey);
  border: 1px solid var(--color-grey);
  color: var(--color-white);
  transition: 0.3s ease-in-out;
}

.infoPageImageBox {
  height: 420px;
  width: 100%;
  overflow: hidden;
  border-radius: 24px;
}

.infoPageImageBox img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.infoPageImageBox::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100px;
  background-color: transparent;
  margin-bottom: -18px;
  margin-left: -5px;
  border: 1px solid var(--color-primary);
  z-index: -1;
  animation: rotationbottom 6s infinite linear;
}

.infoPageImageBox::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-top: -40px;
  margin-right: -30px;
  border: 3px dashed var(--color-primary);
  z-index: -1;
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationbottom {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.saved_box {
  position: relative;
  margin-right: 32px !important;
}
.saved_box svg {
  font-size: 1rem;
  color: var(--color-black);
}
.saved_box span {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  margin-right: -15px !important;
  margin-top: -4px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: var(--color-black);
}
.profileCard {
  background-color: var(--color-white);
  border-radius: 16px;
  padding: 24px;
}
.profileMenuTop {
  border-bottom: 1px dashed var(--color-light);
  padding-bottom: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.profileMenuTopUser {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--color-purple);
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileMenuTopUser span {
  color: var(--color-white);
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8rem;
}
.profileMenuTopTitle h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 6px;
}
.profileMenuTopTitle p {
  font-size: 1.1rem;
  color: var(--color-grey);
  margin-bottom: 0px;
}
.profileMenuBottom {
  position: relative;
}
.profileMenuBottom ul {
  margin: 0;
  padding: 0;
}
.profileMenuBottom ul li {
  position: relative;
  list-style: none;
}
.profileMenuBottom ul li a {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 16px 24px;
  background: var(--color-white);
  color: var(--color-black);
  margin-bottom: 6px;
  border-radius: 12px;
  padding-left: 50px;
}
.profileMenuBottom ul li.active a {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 16px 24px;
  background: var(--color-bg);
  border-radius: 12px;
  padding-left: 50px;
}
.profileMenuBottom ul li.active svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 1.4rem;
  margin-top: 16px;
  margin-left: 14px;
  color: var(--color-purple);
}
.profileMenuBottom ul li svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 1.4rem;
  margin-top: 16px;
  margin-left: 14px;
}
.profileMenuBottom ul li:hover a {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 16px 24px;
  background: var(--color-bg);
  border-radius: 12px;
  padding-left: 50px;
}
.profileMenuBottom ul li:hover svg {
  color: var(--color-purple);
}
.profileEditBoxTitle {
  border-bottom: 1px dashed var(--color-light);
  padding-bottom: 24px;
  margin-bottom: 24px;
  font-size: 1.1rem;
  color: var(--color-purple);
}
.profileForm {
  margin-bottom: 30px;
}

.profileForm label {
  width: 100%;
  color: var(--color-black);
  margin-bottom: 8px;
  font-size: 1rem;
}

.profileForm input {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  color: var(--color-black);
  border: 1px solid var(--color-light);
  padding: 4px 16px;
  font-size: 1rem;
}
.profileForm input:focus-visible {
  outline: 1px solid var(--color-purple);
}

.profileForm textarea {
  width: 100%;
  min-height: 180px;
  border-radius: 12px;
  border: 1px solid var(--color-light);
  padding: 16px;
  font-size: 1rem;
}
.saveProfile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.saveButton {
  border: 3px solid var(--color-purple);
  background: var(--color-purple);
  color: var(--color-white);
  padding: 12px 24px;
  border-radius: 12px;
  transition: 0.3s ease;
  font-size: 1rem;
  font-weight: bold;
}
.saveButton:hover {
  border: 3px solid var(--color-purple);
  color: var(--color-purple);
  padding: 12px 24px;
  background: var(--color-white);
  transition: 0.3s ease;
}
.footerSocial {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}
.singleSocial {
  margin-right: 6px;
  transition: 0.3s ease;
}
.singleSocial:hover {
  transform: scale(1.2);
  transition: 0.3s ease;
}
.singleSocial img {
  height: 30px !important;
  width: 30px !important;
  filter: grayscale(0) !important;
}
.footerContactIcons li img {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  transition: 0.3s ease;
  filter: grayscale(1);
}
.footerContactIcons li:hover img {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  transition: 0.3s ease;
  filter: grayscale(0.2);
}
.productSection {
  padding: 80px 0px;
}
.propertyDetailsSection {
  padding: 40px 0px;
}
.productCard {
  border-radius: 16px;
  padding: 24px;
}
.productCardTopSortBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed var(--color-light);
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.productCardTopSortBoxLeft h4 {
  font-size: 1rem;
  margin-bottom: 0px;
  color: var(--color-black);
}
.productCardTopSortBoxLeft h4 span {
  color: var(--color-purple);
  font-weight: bold;
  font-size: 1rem;
}
.trigger-button i {
font-size: 2em;
}
.singleProductGridBox {
  position: relative;
  background-color: var(--color-white);
  padding: 10px;
  border-radius: 12px;
  transition: 0.6s ease;
  margin-bottom: 24px;
}
.singleProductGridBox:hover {
  box-shadow: 3px 3px 10px #ddd;
  transition: 0.3s ease;
}
.singleProductGridBoxImage {
  height: 240px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.singleProductGridBoxImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
  transition: 0.6s ease;
}
.singleProductGridBoxImageBox {
  position: relative;
}
.singleProductGridBoxImageBox .swiper-button-prev {
  background: var(--color-white);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  left: -30px;
}

.singleProductGridBoxImageBox .swiper-button-next {
  background: var(--color-white);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  right: -30px;
}
.singleProductGridBoxImageBox:hover .swiper-button-next {
  margin-right: 40px;
  transition: 0.3s ease;
}
.singleProductGridBoxImageBox:hover .swiper-button-prev {
  margin-left: 40px;
  transition: 0.3s ease;
}

.singleProductGridBoxImageBox .swiper-button-next::after, .singleProductGridBoxImageBox .swiper-button-prev::after {
  font-size: 0.8rem;
  color: var(--color-primary);
  font-weight: bold;
}
.singleProductGridTag {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 16px;
  margin-top: 16px;
  height: 30px;
  width: auto;
  z-index: 1;
  background: var(--color-green);
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.singleProductGridTag h4 {
  color: var(--color-white);
  font-size: 0.8rem;
  margin-bottom: 0px;
}
.singleProductGridFavourite {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 16px;
  margin-top: 16px;
  z-index: 1;
  background: var(--color-white-60);
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.singleProductGridFavourite svg {
  font-size: 1.1rem;
  color: var(--color-purple);
}
.addedToFafourites {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 30px;
  margin-right: 30px;
  z-index: 11;
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 3px 3px 10px #ddd;
}
.addedToFafourites h4 {
  font-size: 0.9rem;
  color: var(--color-purple);
  margin-bottom: 0px;
}

.singleProductGridPrice {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}
.singleProductGridPrice h4 {
  color: var(--color-black);
  font-size: 1.4rem;
  margin-bottom: 0px;
}
.singleProductGridPrice h4 span {
  font-size: 0.9rem;
  color: var(--color-black);
  font-weight: bold;
  margin-left: 6px;
}
.priceBoxGrid {
  background-color: var(--color-white-60);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 2px;
  border-radius: 10px;
  border: 1px solid var(--color-grey);
}
.priceBoxGrid .singlePrice {
  margin-right: 6px;
  cursor: pointer;
  transition: 0.3s ease;
}
.priceBoxGrid .singlePrice:last-child {
  margin-right: 0px;
}
.singlePrice.active {
  background-color: var(--color-purple);
  border-radius: 8px;
}
.singlePrice.active p {
  color: var(--color-white) !important;
}
.priceBoxGrid .singlePrice p {
  margin-bottom: 0px;
  font-size: 0.8rem;
  padding: 1px 4px;
  color: var(--color-black);
  transition: 0.3s ease;
}
.priceBoxGrid .singlePrice:hover {
  background-color: var(--color-purple);
  border-radius: 8px;
  transition: 0.3s ease;
}
.priceBoxGrid .singlePrice:hover p {
  color: var(--color-white) !important;
}
.singleProductGridBox:hover .singleProductGridBoxImage img {
  transform: scale(1.1);
  transition: 0.6s ease;
}
.propertyGridTags {
  margin-top: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.propertyGridTags .singleTag {
  background-color: var(--color-purple);
  color: var(--color-white);
  font-size: 0.8rem;
  margin-right: 8px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-bottom: 8px;
}
.propertyGridTags .singleTag:last-child {
  margin-right: 0px;
}
.singleProductGridBoxContant h1 {
  color: var(--color-black);
  margin-bottom: 0px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3rem;
}
.singlePropertyGridLocation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
.singlePropertyGridLocation svg {
  margin-right: 8px;
  color: var(--color-grey);
  font-size: 1rem;
}
.singlePropertyGridLocation p {
  color: var(--color-grey);
  font-size: 0.8rem;
  margin-bottom: 0px;
}
.singlePropertyGridMiniBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.singlePropertyGridMini {
  margin-right: 12px;
}
.singlePropertyGridMini:last-child {
  margin-right: 0px;
}
.singlePropertyGridMini svg {
  font-size: 0.9rem;
  color: var(--color-grey);
}
.singlePropertyGridMini span {
  font-size: 1rem;
  color: var(--color-black);
}
.singleProductListBoxImage {
  height: 230px;
  width: 260px;
  border-radius: 6px;
  overflow: hidden;
}
.singleProductListBoxImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
  transition: 0.6s ease;
}
.singleProductListBoxImageBox {
  position: relative;
  width: 260px;
}
.singleProductListBoxImageBox .swiper-button-prev {
  background: var(--color-white);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  left: -30px;
}
.singleProductListBoxImageBox .swiper-button-next {
  background: var(--color-white);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  right: -30px;
}
.singleProductListBoxImageBox:hover .swiper-button-next {
  right: 10px;
  transition: 0.3s ease;
}
.singleProductListBoxImageBox:hover .swiper-button-prev {
  left: 10px;
  transition: 0.3s ease;
}

.singleProductListBoxImageBox .swiper-button-next::after, .singleProductListBoxImageBox .swiper-button-prev::after {
  font-size: 0.8rem;
  color: var(--color-primary);
  font-weight: bold;
}
.singleProductListBox:hover .singleProductListBoxImage img {
  transform: scale(1.1);
  transition: 0.6s ease;
}
.singleProductListTag {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 16px;
  margin-top: 16px;
  height: 30px;
  width: auto;
  z-index: 1;
  background: var(--color-primary);
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.singleProductListTag h4 {
  color: var(--color-black);
  font-size: 0.8rem;
  margin-bottom: 0px;
}
.singleProductListFavourite {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 16px;
  margin-top: 16px;
  z-index: 1;
  background: var(--color-primary);
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.singleProductListFavourite svg {
  font-size: 1.1rem;
  color: var(--color-purple);
}
.singleProductListPrice {
  margin-top: 16px;
  height: 30px;
  width: auto;
  border-radius: 6px;
}
.singleProductListPrice h4 {
  color: var(--color-black);
  font-size: 1.4rem;
  margin-bottom: 0px;
}
.singleProductListPrice h4 span {
  font-size: 0.9rem;
  color: var(--color-black);
}
.singleProductListBoxContant {
  margin-left: 40px;
}
.singleProductListBoxContant h1 {
  color: var(--color-black);
  margin-bottom: 0px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3rem;
}
.singleProductListBox {
  position: relative;
  background-color: var(--color-white);
  padding: 10px;
  border-radius: 12px;
  transition: 0.6s ease;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.singleProductListBox:hover {
  box-shadow: 3px 3px 10px #ddd;
  transition: 0.3s ease;
}
.priceBoxList {
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 4px;
  border-radius: 10px;
  width: fit-content;
}
.priceBoxList .singlePrice {
  margin-right: 6px;
  cursor: pointer;
  transition: 0.3s ease;
}
.priceBoxList .singlePrice:last-child {
  margin-right: 0px;
}
.priceBoxList .singlePrice p {
  margin-bottom: 0px;
  font-size: 0.8rem;
  padding: 1px 4px;
  color: var(--color-black);
  transition: 0.3s ease;
}
.priceBoxList .singlePrice:hover {
  background-color: var(--color-purple);
  border-radius: 8px;
  transition: 0.3s ease;
}
.priceBoxList .singlePrice:hover p {
  color: var(--color-white) !important;
}
.singlePropertyListLocation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
}
.singlePropertyListLocation svg {
  margin-right: 8px;
  color: var(--color-grey);
  font-size: 1rem;
}
.singlePropertyListLocation p {
  color: var(--color-grey);
  font-size: 0.8rem;
  margin-bottom: 0px;
}
.singlePropertyListMiniBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
.singlePropertyListMini {
  margin-right: 30px;
}
.singlePropertyListMini:last-child {
  margin-right: 0px;
}
.singlePropertyListMini svg {
  font-size: 1rem;
  color: var(--color-grey);
}
.singlePropertyListMini span {
  font-size: 1rem;
  color: var(--color-black);
}
.propertyListTags {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.propertyListTags .singleTag {
  background-color: var(--color-purple);
  color: var(--color-white);
  font-size: 0.8rem;
  margin-right: 8px;
  padding: 2px 6px;
  border-radius: 10px;
}
.propertyListTags .singleTag:last-child {
  margin-right: 0px;
}
.myAds {
  border: 1px solid var(--color-light);
}
.myAdsImage {
  height: 200px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.myAdsImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
  transition: 0.6s ease;
}
.singlePropertyEdit {
  border-top: 1px solid var(--color-light);
  margin-top: 10px;
  padding-top: 10px;
  text-align: right;
  margin-bottom: 6px;
}
.editButton {
  font-size: 0.9rem;
  font-weight: normal;
  border-radius: 10px;
  color: var(--color-grey);
  border: 0;
  background: transparent;
  transition: 0.3s ease;
}
.editButton:hover {
  color: var(--color-purple);
  transition: 0.3s ease;
}
.singleHomeService {
  background-color: var(--color-white);
  border-radius: 16px;
  overflow: hidden;
  padding: 32px;
  position: relative;
  width: 100%;
  height: auto;
  transition: 0.3s ease;
}
.singleHomeServiceImage {
  height: 100px;
  width: 100px;
  overflow: hidden;
  margin-bottom: 24px;
  transition: 0.3s ease;
}
.singleHomeServiceImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.singleHomeService h4 {
  font-size: 1.2rem;
  color: var(--color-black);
  margin-bottom: 0px;
}
.singleHomeService:hover {
  box-shadow:3px 3px 6px #ededed;
  transition: 0.3s ease;
}
.singleHomeService:hover .singleHomeServiceImage {
 transform: scale(1.1);
 transition: 0.3s ease;
}
.changeViewButton {
  border: 1px solid var(--bs-border-color);
  background-color: var(--color-white);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 12px;
}
.productCardTopSortBoxRight {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sortBYButton {
  position: relative;
}
.sortBYButton select {
  height: 36px;
  padding-right: 24px;
  font-size: 0.8rem;
}
.sortBYButton svg {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 11px;
  margin-right: 8px;
  color: var(--color-grey);
  font-size: 0.8rem;
}
.paginateNext {
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  border-radius: 50%;
  font-size: 14px;
  font-family: "Nunito";
  color: var(--color-grey);
  height: 45px;
  width: 45px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginatePrev {
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  border-radius: 50%;
  font-size: 14px;
  font-family: "Nunito";
  color: var(--color-grey);
  height: 45px;
  width: 45px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginateNext:hover {
  background-color: var(--color-purple);
  color: var(--color-white);
  transition: 0.3s ease;
}
.paginatePrev:hover {
  background-color: var(--color-purple);
  color: var(--color-white);
  transition: 0.3s ease;
}
.paginateSingle {
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  border-radius: 50%;
  font-size: 14px;
  font-family: "Nunito";
  color: var(--color-grey);
  height: 45px;
  width: 45px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  margin-right: 10px;
  transition: 0.3s ease;
}
.paginateMiddle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginateSingle:first-child {
  margin-left: 10px;
}
.paginateSingle:hover {
  background-color: var(--color-purple);
  color: var(--color-white);
  transition: 0.3s ease;
}
.paginateSingle.active {
  background-color: var(--color-purple);
  color: var(--color-white);
}
.shortBreadcumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-light);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.shortBreadcumbLeft h1 {
  color: var(--color-black);
  font-size: 1rem;
  margin-bottom: 0px;
}
.shortBreadcumbRight ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shortBreadcumbRight ul li {
  list-style: none;
  color: var(--color-grey);
  margin-left: 12px;
}
.shortBreadcumbRight ul li svg {
  color: var(--color-grey);
  font-size: 0.8rem;
}
.shortBreadcumbRight ul li a {
  color: var(--color-grey);
  font-weight: bold;
  transition: 0.3s ease;
}
.singleProductImage {
  height: 500px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.singleProductImage img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
  transition: 0.6s ease;
}
.mainImageSlider {
  position: relative;
  background-color: var(--color-white);
}
.mainImageSlider .swiper-button-prev {
  background: var(--color-purple);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  left: -60px;
}

.mainImageSlider .swiper-button-next {
  background: var(--color-purple);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  right: -60px;
}
.mainImageSlider:hover .swiper-button-next {
  margin-right: 70px;
  transition: 0.3s ease;
}
.mainImageSlider:hover .swiper-button-prev {
  margin-left: 70px;
  transition: 0.3s ease;
}

.mainImageSlider .swiper-button-next::after, .mainImageSlider .swiper-button-prev::after {
  font-size: 0.8rem;
  color: var(--color-white);
  font-weight: bold;
}
.singleProductFavourite {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 16px;
  margin-top: 16px;
  z-index: 1;
  background: var(--color-purple);
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.singleProductFavourite svg {
  font-size: 1.1rem;
  color: var(--color-white);
}
.propertyDetailsLeft {
  margin-top: 30px;
}
.propertyDetailsTags {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.propertyDetailsTags .singleTag {
  background-color: var(--color-white);
  border: 2px solid var(--color-purple);
  color: var(--color-purple);
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 12px;
  padding: 8px 16px;
  border-radius: 10px;
}
.propertyDetailsTags .singleTag:last-child {
  margin-right: 0px;
}
.propertyDetailsLeft h2 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 8px;
}
.propertyDetailsData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.propertyDetailsData p {
  margin-bottom: 0px;
  margin-right: 24px;
  color: var(--color-grey);
}
.propertyDetailsData strong {
  margin-left: 8px;
}
.propertyDetailsData svg {
  color: var(--color-grey);
}
.singlePropertyLocation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 16px;
}
.singlePropertyLocation svg {
  margin-right: 8px;
  color: var(--color-grey);
  font-size: 1rem;
}
.singlePropertyLocation p {
  color: var(--color-grey);
  font-size: 1rem;
  margin-bottom: 0px;
}
.singlePropertyMiniBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  background: var(--color-light);
  padding: 24px;
  border-radius: 16px;
}
.singlePropertyMini {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.singlePropertyMini svg {
  font-size: 0.9rem;
  color: var(--color-purple);
}
.singlePropertyMini span {
  font-size: 1rem;
  color: var(--color-black);
  margin-left: 16px;
}
.singlePropertyMini span strong {
  margin-left: 10px;
  font-size: 1.1rem;
}
.propertyDescription {
  background: var(--color-white);
  padding: 24px;
  border-radius: 24px
}
.propertyDescription h3 {
  color: var(--color-black);
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 1.3rem;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 16px;
}
.showMoreText {
  text-decoration: underline;
  font-size: 0.9rem;
  margin-top: 8px;
}
.propertyDetails {
  margin-top: 24px;
}
.propertyDetailsRight {
  margin-top: 30px;
  background: var(--color-light);
  padding: 32px;
  border-radius: 16px;
}
.singleProductPrice {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.singleProductPrice h4 {
  color: var(--color-black);
  font-size: 1.6rem;
  margin-bottom: 0px;
  font-weight: bold;
}
.singleProductPrice h4 span {
  font-size: 0.9rem;
  color: var(--color-black);
}
.priceBoxDetails {
  background-color: var(--color-white-60);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 4px;
  border-radius: 10px;
}
.priceBoxDetails .singlePropertyPrice {
  margin-right: 6px;
  cursor: pointer;
  transition: 0.3s ease;
}
.priceBoxDetails .singlePropertyPrice:last-child {
  margin-right: 0px;
}
.singlePropertyPrice.active {
  background-color: var(--color-purple);
  border-radius: 8px;
}
.singlePropertyPrice.active p {
  color: var(--color-white) !important;
}
.priceBoxDetails .singlePropertyPrice p {
  margin-bottom: 0px;
  font-size: 0.8rem;
  padding: 1px 4px;
  color: var(--color-black);
  transition: 0.3s ease;
}
.priceBoxDetails .singlePropertyPrice:hover {
  background-color: var(--color-purple);
  border-radius: 8px;
  transition: 0.3s ease;
}
.priceBoxDetails .singlePropertyPrice:hover p {
  color: var(--color-white) !important;
}
.oneSquarePrice p {
  color: var(--color-grey);
  margin-top: 4px;
  font-size: 0.9rem;
  margin-bottom: 0px;
}
.oneSquareArea {
  color: var(--color-black);
  margin-top: 8px;
  font-size: 1rem;
  margin-bottom: 0px;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  padding-bottom: 0px;
  margin-bottom: 16px;
}
.adAuthor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adAuthorImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--color-purple);
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adAuthorImg h4 {
  color: var(--color-white);
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 0px;
}
.adAuthorText h5 {
  margin-bottom: 2px;
  font-size: 1rem;
  color: var(--color-black);
  font-weight: bold;
}
.adAuthorText p {
  margin-bottom: 0px;
  color: var(--color-grey);
  font-size: 0.9rem;
}
.adAuthorPhone {
  background-color: var(--color-purple);
  height: 60px;
  width: 100%;
  border-radius: 20px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adAuthorPhone svg {
  color: var(--color-white);
  font-size: 1.3rem;
}
.phoneNumber {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-white);
  margin-left: 12px;
}
.relatedAds {
  margin-top: 80px;
  margin-bottom: 40px;
}
.relatedAds h6 {
    color: var(--color-black);
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 1.3rem;
}
.propertyDetailsProp h4 {
  font-size: 1rem;
  color: var(--color-black);
  margin-bottom: 16px;
}
.singleFilterBox {
  margin-bottom: 32px;
  border-bottom: 1px solid var(--color-light);
  padding-bottom: 16px;
}
.singleFilterBox:last-child {
  border-bottom: 0;
}
.errorMessage {
  font-size: 0.7rem;
  font-weight: bold;
  margin-top: 0.3rem;
  padding-left: 0.2rem;
}
.sectionTitleLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sectionTitleLogoRight {
  margin-left: 24px;
}
.sectionTitleLogoLeft {
  height: 50px;
  width: 50px;
  overflow: hidden;
}
.sectionTitleLogoLeft img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.exclusiveSection {
  padding: 0px;
}
.districtSection {
  padding: 80px 0px;
}
.reluxSection {
  padding: 0;
}
.sliderSearchInput input::placeholder {
  color: #9f9f9f;
  opacity: 1;
}
.sliderSearchInput input::-ms-input-placeholder {
  color: #9f9f9f;
}
.singleProductGridTagRelux {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 16px;
  margin-top: 16px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.singleProductGridTagRelux h4 {
  color: var(--color-black);
  font-size: 0.8rem;
  margin-bottom: 0px;
  font-weight: bold;
}
.reluxAd {
  height: 30px;
  width: auto;
  background: var(--color-primary);
  padding: 6px 12px;
  border-radius: 6px;
}
.reluxAd h4 {
  color: var(--color-black);
  font-size: 0.8rem;
  margin-bottom: 0px;
  font-weight: bold;
}
.exclusiveAd {
  height: 30px;
  width: auto;
  background: var(--color-green);
  padding: 6px 12px;
  border-radius: 6px;
  margin-left: 12px;
}
.exclusiveAd h4 {
  color: var(--color-white);
  font-size: 0.8rem;
  margin-bottom: 0px;
  font-weight: bold;
}
.tabsBox {
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px;
  border-radius: 16px;
  outline: 1px solid var(--color-grey-secondary);
}
.singleTab {
  position: relative;
  color: var(--color-grey);
  opacity: 0.6;
  cursor: pointer;
  transition: 0.3s ease;
}
.singleTab:hover {
  color: var(--color-green);
  opacity: 1;
  transition: 0.3s ease;
}
.singleTab.active {
  font-weight: bold;
  color: var(--color-green);
  opacity: 1;
}
.singleTab::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 0px;
  margin-right: -76px;
  margin-top: 12px;
  opacity: 0.4;
  border-top: 1px dashed var(--color-grey);
}
.singleTab:last-child::after {
  display: none;
}
.cardBox {
  background: var(--color-white);
  padding: 24px;
  border-radius: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cardBoxTitle {
  border-bottom: 1px dashed var(--color-green);
  padding-bottom: 10px;
  margin-bottom: 24px;
}
.cardBoxTitle h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 0px;
}
.customFormGroupSelect label {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  margin-bottom: 10px;
}
.customFormGroupSelect .css-13cymwt-control {
  border-radius: 18px;
  height: 56px;
  padding: 10px;
  border: 1px solid var(--color-grey-secondary);
}
.customFormGroupSelect .css-t3ipsp-control {
  border-radius: 18px;
  height: 56px;
  padding: 10px;
}
.customFormGroupSelect .css-1u9des2-indicatorSeparator {
  background-color: var(--color-light);
  margin-bottom: 0px;
  margin-top: 0px;
}
.customFormGroupInput {
  position: relative;
}
.customFormGroupInput label {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  margin-bottom: 10px;
}
.customFormGroupInput input {
  border-radius: 18px;
  height: 56px;
  padding: 12px;
  width: 100%;
  border: 1px solid var(--color-grey-secondary);
}
.construction {
  background: #f7c546;
  color: #212121;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  font-size: 16px;
}
.addCustomField {
  background: var(--color-white);
  border: 1px dashed var(--color-green);
  padding: 8px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-black);
  transition: 0.3s ease;
}
.addCustomField:hover {
  background: var(--color-white-60);
}
.deleteCustomField {
  position: absolute;
  right: 0;
  top: 0;
  height: 54px;
  margin-top: 34px;
  border: 0;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 1px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  background: var(--color-grey-secondary);
  color: var(--color-black);
  transition: 0.3s ease;
}
.deleteCustomField:hover {
  background: var(--color-light);
  transition: 0.3s ease;
}
.cadasterAddBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}
.customCheckbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.customCheckbox input {
  display: none;
}
.customCheckbox strong {
  margin-left: 12px;
}
.customCheckboxBox {
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border: 2px solid var(--color-grey-secondary);
  display: inline-block;
  position: relative;
}
.customCheckboxBox::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background-color: var(--color-green);
  transition: transform 0.2s;
}
.customCheckbox input:checked + .customCheckboxBox::after {
  transform: translate(-50%, -50%) scale(1);
}
.customLabelCheck {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.subTitle {
  position: relative;
  width: 100%;
  font-weight: bold;
  margin-bottom: 12px;
}
.subTitle::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 40px;
  margin-bottom: -10px;
  background-color: var(--color-green);
}
.subTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 20px;
  margin-bottom: -10px;
  margin-left: 46px;
  background-color: var(--color-grey);
}
.dateBox .react-datepicker-wrapper {
  width: 100%;
}
.addPropertyButton {
  background-color: var(--color-white);
  height: 60px;
  width: fit-content;
  border-radius: 20px;
  padding: 16px 30px;
  color: var(--color-purple);
  font-weight: bold;
  border: 2px solid var(--color-purple);
  transition: 0.3s ease;
}
.addPropertyButton:hover {
  background-color: var(--color-purple);
  border: 2px solid var(--color-purple);
  color: var(--color-white);
  transition: 0.3s ease;
}
.singleFilterBoxShow {
  cursor: pointer;
  color: var(--color-purple);
}
.filterBoxMain {
  background: var(--color-white);
  border: 2px solid var(--color-purple);
  border-radius: 8px;
  height: 60px;
}
.textDescription {
  border: 1px solid var(--color-light);
  padding: 16px;
  color: var(--color-grey);
}
.textDescription:focus-visible {
  outline: 0;
}
.mainDescTabs {
  border: 0 !important;
}
.mainDescTabs .nav-link {
  color: var(--color-grey);
}
.mainDescTabs .nav-link.active {
  border-color: var(--color-light);
  color: var(--color-purple);
  font-weight: bold;
}
.uploadMainImageBox {
  border: 2px dashed var(--color-purple);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  width: 100%;
  border-radius: 16px;
  cursor: pointer;
}
.uploadMainImage {
  text-align: center;
}
.uploadMainImage svg {
  color: var(--color-purple);
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
}
.uploadMainImage h4 {
  color: var(--color-purple);
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 18px;
}
.uploadedImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 6px var(--color-grey-secondary);
  height: 220px;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
}
.uploadedImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.uploadedImage span {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  background: var(--color-white);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.uploadedImage span svg {
  color: var(--color-red);
  font-size: 15px;
}
.notifyUpload {
  margin-top: 24px;
  margin-bottom: 0px;
  color: var(--color-grey);
}
.makeCover {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 50px;
  background: var(--color-white);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.makeCover svg {
  color: var(--color-black);
  font-size: 15px;
}
.makeCover.active svg {
  color: var(--color-purple);
  font-size: 15px;
}

.listView .singleProductGridBox {
  display: flex;
}
.listView .singleProductGridBoxImageBox {
  width: 300px;
}
.listView .singleProductGridBoxContant {
  width: calc(100% - 300px);
  padding: 0 20px;
}
.listView .listItemDescription {
  color: var(--color-grey);
  font-size: 1rem;
  margin-bottom: 0px;
  margin-top: 8px;
}
.propertyCharacteristics {
  margin-bottom: 24px;
}
.propertyCharacteristics h3 {
  color: var(--color-black);
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 1.3rem;
}
.propertyCharacteristicsInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 32px;
  border-radius: 16px;
}
.propertyCharacteristicsSingleBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.propertyCharacteristicsSingleBoxLeft {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-light);
  border-radius: 50%;
  margin-right: 12px;
}
.propertyCharacteristicsSingleBoxLeft svg {
  font-size: 1.2rem;
  color: var(--color-purple);
}
.propertyCharacteristicsSingleBoxRight p {
  color: var(--color-grey);
  font-size: 0.9rem;
  margin-bottom: 2px;
}
.propertyCharacteristicsSingleBoxRight h5 {
  font-size: 0.9rem;
  color: var(--color-black);
  font-weight: 500;
  margin-bottom: 0px;
}
.detailsCardBox {
  background-color: var(--color-white);
  border-radius: 24px;
  padding: 24px;
  margin: 24px 0px;
}
.propertyCharacteristics {
  background-color: var(--color-white);
  border-radius: 24px;
  padding: 24px;
}
.mainParamBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.mainParamBox:last-child {
  margin-bottom: 0px;
}

.paramIcon svg {
  font-size: 1.2rem;
  color: var(--color-purple);
  margin-right: 10px;
}
.paramText {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paramText h5 {
  color: var(--color-black);
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0px;
  min-width: 250px;
}
.paramText h6 {
  font-size: 0.9rem;
  padding-left: 12px;
  margin-bottom: 0px;
  min-width: 200px;
}
.paramText p {
  font-size: 0.9rem;
  padding-left: 12px;
  margin-bottom: 0px;
}
.paramText strong {
  font-size: 1rem;
}
.propertyCharacteristics h3 {
  color: var(--color-black);
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 1.3rem;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 16px;
}
.propertyDetailsProp h3 {
  color: var(--color-black);
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 1.2rem;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 16px;
}
.mtop24 {
  margin-top: 24px;
}
.mainListParamBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.paramListIcon svg {
  font-size: 1.2rem;
  color: var(--color-purple);
  margin-right: 10px;
}
.paramListText {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paramListText h5 {
  color: var(--color-black);
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0px;
}
.paramListText p {
  margin-bottom: 0px;
  font-size: 0.9rem;
  margin-left: 24px;
}
.paramListText span {
  margin-bottom: 0px;
  font-size: 0.9rem;
  margin-left: 4px;
  font-weight: bold;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.propertyDetailsInner {
  position: relative;
  margin-top: 20px;
}
.propertyDetailsInner h4 {
  color: var(--color-purple);
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 0.9rem;
  padding-left: 20px;
}
.propertyDetailsInner h4::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-purple);
  height: 3px;
  width: 12px;
  border-radius: 30px;
  margin-top: 10px;
}
